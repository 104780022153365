import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Menu } from "@material-ui/icons"

import alexa from "../images/alexa.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem`,
        maxWidth: 960,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `#000`,
            textDecoration: `none`,
            fontFamily: "Saira",
            fontWeight: 500,
            fontSize: ".8em",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={alexa}
            width={64}
            alt="Alexa Improvise"
            style={{
              marginBottom: 0,
              marginRight: 20,
            }}
          />{" "}
          <span style={{}}>{siteTitle}</span>
        </Link>
      </h1>
      <Menu
        style={{
          position: `relative`,
          marginLeft: `1em`,
          color: `#000`,
        }}
        fontSize="large"
      ></Menu>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
